.App {
    text-align: center;
}

.pro-item-content {
    padding: 2px 0px;
}

.pro-inner-item {
    padding-right: 20px !important;
}

.has-shadow {
    box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.88);
}

.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 8px;
}

.MuiPaper-rounded {
    border-radius: 16px !important;
}

.SearchInput .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 18px;
}

.switch-wrapper {
    height: 100%;
}

.switch-wrapper > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    flex: 1;
}

html, body {
    height: 100vh;
}

#root {
    height: 100vh;
}