.circleAnimationContainer {
  position: absolute;
  z-index: -1;
}

.circleAnimation {
    border-radius: 50%;
    background-color: #80d3d0;
    position: absolute;
    top: -25px;
    left: -25px;
    width: 50px;
    height: 50px;
    opacity: 0;
    animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: scale(2, 2);
    opacity: 0;
  }
}